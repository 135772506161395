import server from "../axios.config.js";
// const baseURL = "http://110.87.103.59:18086/farmfriend"

// 添加圈舍
export const AddEnclosure = function (param) {
  return server({
    method: "POST",
    url: `http://110.87.103.59:18086/farmfriend/pen/save`,
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: param
  });
};

// 圈舍列表
export const getEnclosureList = function () {
  return server({
    method: "get",
    url: `http://110.87.103.59:18086/farmfriend/pen/selectList`
  });
};

// 牲畜详情
export const EnclosureDetailsInit = function(id) {
  return server({
    method: "get",
    url: `http://110.87.103.59:18086/farmfriend/animal/selectAnimalVariety?penId=${id}`
  })
}

// 圈舍查询
export const getDetailsInit = function(id) {
  return server({
    method: "get",
    url: `http://110.87.103.59:18086/farmfriend/pen/getById?id=${id}`
  })
}
// 养殖的类型
export const getSelectMyAnimalType = function() {
  return server({
    method: "get",
    url: "http://110.87.103.59:18086/farmfriend/animal/selectMyAnimalType"
  })
}
// 删除圈舍
export const postDeletePenLand = function (id) {
  return server({
    method: "post",
    url: 'http://110.87.103.59:18086/farmfriend/pen/delete',
    data: {
      id
    }
  })
}

// 圈舍编辑
export const PostPenUpdate = function(data) {
  return server({
    method: "post",
    url: "http://110.87.103.59:18086/farmfriend/pen/update",
    data: data
  })
}